import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import useCDNImage from '../hooks/useCDNImage'
import { gridSizes } from '../common/gatsbyImageData'
import IconLoaders from '../icons/IconLoaders'

const BrandCard = ({ brand, image, url, qty }) => {
  const finalImage = useCDNImage(image, { productType: 'Mens Watch' }, gridSizes, '20vw')
  return (
    <Link to={url} className="flex flex-col items-center w-2/5 sm:w-[calc(7rem+2.3vw)] group">
      <div className="aspect-square bg-gray-100 w-full flex justify-center items-center p-3">
        {finalImage ? (
          <GatsbyImage
            image={finalImage?.gatsbyImageData}
            alt={brand}
            className={
              'mix-blend-multiply group-hover:scale-110 duration-300 w-full h-full aspect-square'
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
          <IconLoaders type={'Mens Watch'} flashing={false} />
        )}
      </div>
      <h2 className="md:uppercase text-sm my-1 mt-2 text-center h-10 flex items-center line-clamp-2 ">
        {brand} ({qty})
      </h2>
    </Link>
  )
}
BrandCard.propTypes = {
  brand: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
}

const AllWatchBrands = () => {
  const { brands } = useStaticQuery(graphql`
    query AllBrandsQuery {
      brands: watchBrandsCategories {
        id
        name
        urlPath
        productsInStock
        categoryImage {
          gatsbyImageDataMock
        }
      }
    }
  `)

  // sort brands by qty of watches in stock than by name
  brands.sort((a, b) => {
    const hasStockA = a.productsInStock > 0 ? 1 : 0
    const hasStockB = b.productsInStock > 0 ? 1 : 0
    if (hasStockA > hasStockB) {
      return -1
    } else if (hasStockA < hasStockB) {
      return 1
    } else {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    }
  })

  return (
    <Layout
      className={'mx-auto'}
      breadcrumbs={false}
      h1="Preowned Watches - All Brands | Rolex, Cartier, Patek Philippe and others | Gray and Sons"
      canonical="/all-watch-brands/"
    >
      <Seo
        title="All Watch Brands"
        description="Discover our exclusive collection of used watches, featuring certified preowned luxury watches from Rolex, Cartier, Patek Philippe, and many more luxury brands."
        canonical="/all-watch-brands/"
      />
      <section className="w-full max-w-[1366px] mx-auto flex gap-[calc(.5rem+1.5vw)] justify-center items-center my-5 md:my-10 flex-wrap">
        {brands.map(brand => (
          <BrandCard
            key={brand.id}
            brand={brand.name}
            url={brand.urlPath}
            qty={brand.productsInStock}
            image={brand.categoryImage}
          />
        ))}
      </section>
    </Layout>
  )
}

export default AllWatchBrands
